import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["bar"]

  connect() {
    this.footer = document.querySelector("footer")
    if (!this.footer) return

    this.updateBar()
    window.addEventListener("scroll", this.updateBar)
  }

  disconnect() {
    window.removeEventListener("scroll", this.updateBar)
  }

  updateBar = () => {
    const footerRect = this.footer.getBoundingClientRect()
    const windowHeight = window.innerHeight
    let offset = footerRect.top - windowHeight
    if (offset < 0) {
      if (offset <= -40 && offset > -85 && offset != -42.5) {
        this.barTarget.style.transform = `translateY(-42px)`
        this.footer.style.transform = `translateY(44px)`
      } else {
        this.barTarget.style.transform = `translateY(-42px)`
        this.footer.style.transform = `translateY(21px)`
      }
    } else {
      this.barTarget.style.transform = `translateY(0)`
    }
  }
}
