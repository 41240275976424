import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["radio", "button"]

  connect() {
    this.buttonTarget.disabled = true
  }

  toggleButton() {
    if (this.radioTargets.some(radio => radio.checked)) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }

  redirectToPath(event) {
    event.preventDefault()
    if (this.radioTargets[0].checked) {
      window.location.href = "/reclamos/seguimiento"
    }
    else if (this.radioTargets[1].checked) {
      window.location.href = "/reclamos/historial"
    }
  }
}
