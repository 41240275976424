import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "selectAll", "selectedCount", "actionBox"]

  connect() {
    this.selectedCodes = []
    this.updateCount()
  }

  toggleAll() {
    const isChecked = this.selectAllTarget.checked
    this.selectedCodes = []
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = isChecked
      if (isChecked) {
        this.addCode(checkbox.dataset.code)
      }
    })
    this.updateCount()
  }

  updateCount() {
    const selectedCount = this.selectedCodes.length
    this.selectedCountTarget.textContent = `${selectedCount} reclamos seleccionados`
    if (selectedCount > 0) {
      this.actionBoxTarget.classList.remove('hidden')
    } else {
      this.actionBoxTarget.classList.add('hidden')
    }
  }

  deselectAll() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
      this.removeCode(checkbox.dataset.code)
    })
    this.updateCount()
  }

  onCheckboxChange(event) {
    const checkbox = event.target
    const code = checkbox.dataset.code
    if (checkbox.checked) {
      this.addCode(code)
    } else {
      this.removeCode(code)
    }
    this.updateCount()
  }

  addCode(code) {
    if (!this.selectedCodes.includes(code)) {
      this.selectedCodes.push(code)
    }
  }

  removeCode(code) {
    const index = this.selectedCodes.indexOf(code)
    if (index > -1) {
      this.selectedCodes.splice(index, 1)
    }
  }

  viewSelectedDetails() {
    if (this.selectedCodes.length > 0) {
      const url = `/reclamos/${this.selectedCodes[0]}?codes=${this.selectedCodes.join(",")}`
      window.location.href = url
    } else {
      alert("No se ha seleccionado ningún reclamo.")
    }
  }
}
