import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submitBox", "docType", "docId"]

  connect() {
    this.originalDocType = this.docTypeTarget.value
    this.originalDocId = this.docIdTarget.value
  }

  checkChanges() {
    const docTypeChanged = this.docTypeTarget.value !== this.originalDocType
    const docIdChanged = this.docIdTarget.value !== this.originalDocId && this.docIdTarget.value.length > 0
    if (docTypeChanged || docIdChanged) {
      this.submitBoxTarget.classList.remove('hidden')
    } else {
      this.submitBoxTarget.classList.add('hidden')
    }
  }

  disableSubmit() {
    this.originalDocType = this.docTypeTarget.value
    this.originalDocId = this.docIdTarget.value
    this.submitBoxTarget.classList.add('hidden')
  }
}
